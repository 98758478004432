<template>  
  <div>
    <v-dialog persistent v-model="dialog" width="50%">
      <div class="exp_asisp">
        <!-- CABECERA -->

        <base_Header :Entorno="Entorno.header" @onEvent="cancelar"/>        

        <!-- CUERPO -->
        <v-sheet class="contenedor" :elevation="4">          
          <!-- <div class="subtitulo">ASISTENCIA PSICOLÓGICA</div> -->
          <div class="subtitulo">{{ record.control }}

          </div>
          
          <v-textarea
            v-bind="$textarea"
            v-model="comentario"
            label="Introduce Información necesaria"
            rows="5"
            no-resize
          />          
          <div class="pie pl-2">
              <b>Debera solicitar e introducir al menos estos datos de la persona asistida:</b><br>
              * Apellidos, nombre y Edad.<br>
              * Teléfono de contacto del beneficiario o de su tutor.<br>
              * Lugar donde debe de personarse el psicólogo: Tanatorio.<br>
              <small>Excepcionalmente, si la persona beneficiaria está imposibilitada para desplazarse al tanatorio, el servicio se prestará en su domicicilio.</small><br>
              * Fecha y horarios para la asistencia.<br>
              * Descripción del caso o situación.<br>
          </div>
          <div>
            <v-btn v-bind="$cfg.btn.busca_with_text" @click="aceptar">Aceptar</v-btn>
            <v-btn v-bind="$cfg.btn.busca_with_text" @click="cancelar">Cancelar</v-btn>
          </div>
        </v-sheet>
      </div>
    </v-dialog>
  </div>
</template>



<script>
// -------------------------IMPORTACIONES
import { mixinCtrls } from "@/mixins/mixinCtrls";
import plugs from "@/common/general_plugs";
const base_Header = () => plugs.groute("base_Header.vue", "base");
//import { mixinMto } from "@/mixins/mixinMto.js";
//
// -------------------------EXPORTACIONES
export default {
  mixins: [mixinCtrls],
  components: { base_Header },
  props: {    
    record: {},
    
  },

  //
  //
  //
  // -------------------------DATA

  data() {
    return {
      dialog: true,
          
      comentario: "",
      Entorno: {},
      
    };
  },
  created() {    
    this.ini_data();
  },
  
  methods: {
    //
    ini_data() {
      this.Entorno = JSON.parse(JSON.stringify(this.$cfg.base.F));
      //
      this.dialog = true;
      // ENTORNO      
      this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo["F"]));      
      this.Entorno.header.titulo = "ASISTENCIA PSICOLÓGICA";
    },

    async aceptar() {

      if (this.comentario == "") {
        this.$root.$alert.open("Debe introducir texto Informativo", "info");
        return;
      }      

      let args = {
        tipo: "fnc",
        accion: "expediente",
        fn_args: { accion: "asis_psico", id: this.record.id, txt: this.comentario },
      }      
      let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });      

      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, 'error');
        return;
      }
      
      //cerramos y enviamos texto ...
      this.$emit("onEvent", { accion: "aceptar", txt: this.comentario });   
    },

    cancelar() {        
      this.$emit("onEvent", { accion: "cancelar" });      
    },   
   
  },
};

</script>

<style scoped>
.subtitulo {
  font-size: 1.5rem;
  color: red;
  text-align: center;
}
.aviso {
  font-size: 1rem;
  text-align: center;
}
.pie {
  font-size: 1rem;
  color: blue;
  text-align: left;
  margin: 10px;

}
</style>

